<template>
  <div class="component-wrapper d-flex flex-column align-center pa-5">
    <img class="logo mb-4" src="@/assets/logo.svg" />

    <form
      @submit.prevent="submit"
      class="d-flex flex-column form"
      novalidate
      autocomplete="off"
    >
      <v-text-field
        label="Κωδικός Εισιτηρίου"
        outlined
        class="flex-grow-0 mb-2"
        dense
        type="email"
        v-model="roomName"
        color="primary"
        :error-messages="
          invalidTicket
            ? ' Δεν μπορείτε να συνδεθείτε στο event που δηλώσατε. Δοκιμάστε πάλι να εισάγετε τον κωδικό μετάδοσης. Αν ο κωδικός σας είναι σωστός τότε είτε έχει κάποιο τεχνικό πρόβλημα ο διοργανωτής, οπότε προσπαθήστε πάλι σε λίγο, είτε δεν έχει ξεκινήσει ακόμα η μετάδοση.'
            : ''
        "
        @input="invalidTicket = false"
      ></v-text-field>

      <v-btn
        color="primary"
        class="align-self-center"
        depressed
        type="submit"
        :loading="loader"
        :disabled="!roomName"
        >Έναρξη Παρουσίασης</v-btn
      >
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      roomName: "",
      loader: false,
      invalidTicket: false,
    };
  },
  methods: {
    async submit() {
      this.loader = true;
      this.invalidTicket = false;

      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/live/connectToStream`,
          {
            roomName: this.roomName,
          }
        );

        this.$router.push({
          name: "liveRoom",
          params: { accessToken: res.data.accessToken },
        });
      } catch (e) {
        console.log(e);
        if (e.response.status == 400) this.invalidTicket = true;
      }

      this.loader = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  height: 30px;
}

.form {
  width: 250px;
}
</style>
