<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {};
</script>

<style lang="scss">
//OVERRIDES
.v-btn:not(.v-btn--icon),
.v-tab {
  letter-spacing: normal !important;
  text-transform: none !important;
  font-weight: 400 !important;
}

*:not(.v-icon),
*:not(.v-icon):before,
*:not(.v-icon):after {
  letter-spacing: normal !important;
}

.v-main__wrap {
  display: flex;
  flex-direction: column;
}

//GLOBAL STYLES
.component-wrapper {
  flex-grow: 1;
  position: relative;
  padding: 16px;
}
</style>
