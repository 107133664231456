<template>
  <div class="component-wrapper d-flex flex-column align-center justify-center">
    <div class="player-wrapper mb-6" ref="playerWrapper"></div>

    <div class="d-flex align-center">
      <v-btn
        class="mr-2"
        depressed
        outlined
        color="primary"
        @click="
          imageDialog = {
            open: true,
            image: null,
            video: true,
          }
        "
        >Καλωσόρισμα</v-btn
      >

      <v-btn
        class="mr-2"
        depressed
        outlined
        color="primary"
        @click="imageDialog = { open: true, image: 'info.jpg' }"
        >Πληροφορίες εκδήλωσης</v-btn
      >

      <v-btn
        class="mr-2"
        depressed
        outlined
        color="primary"
        @click="imageDialog = { open: true, image: 'participants.jpg' }"
        >Συντελεστές</v-btn
      >

      <v-btn color="error" depressed @click="$router.push('/')"
        >Αποχώρηση</v-btn
      >
    </div>

    <v-dialog v-model="discardDialog.open" max-width="500">
      <div class="white pa-3">
        <div class="body-1 text-center mb-4">
          Θέλετε να αποχωρήσετε από την μετάδοση;
        </div>

        <div class="d-flex justify-center">
          <v-btn depressed color="error" class="mr-3" @click="discard"
            >Αποχώρηση</v-btn
          >

          <v-btn
            color="primary"
            depressed
            @click="
              discardDialog = { open: false, allowLeave: false, to: null }
            "
            >Παραμονή</v-btn
          >
        </div>
      </div>
    </v-dialog>

    <v-dialog v-model="imageDialog.open" max-width="1300">
      <div class="white pa-3 d-flex flex-column" v-if="imageDialog.open">
        <div class="text-h6 text-center" v-if="imageDialog.video">
          Επ. Υπεύθυνος Έργου - Απόστολος Παπαγιαννάκης
        </div>

        <v-btn
          @click="imageDialog.open = false"
          class="ml-auto mt-1 mr-1"
          small
          icon
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <div class="d-flex" v-if="imageDialog.image">
          <v-btn
            class="my-auto primary"
            icon
            color="white"
            v-if="imageDialog.image.includes('info')"
            @click="currentImage == 0 ? (currentImage = 1) : (currentImage = 0)"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>

          <img
            :src="require(`@/assets/${currentImage}-${imageDialog.image}`)"
            class="img"
          />

          <v-btn
            class="my-auto primary"
            icon
            color="white"
            v-if="imageDialog.image.includes('info')"
            @click="currentImage == 0 ? (currentImage = 1) : (currentImage = 0)"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>

        <vueVimeoPlayer
          class="mx-auto"
          v-if="imageDialog.video"
          video-url="https://vimeo.com/714489659/99387fa60b"
        />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { Player } from "@twilio/live-player-sdk";
import { vueVimeoPlayer } from "vue-vimeo-player";

export default {
  components: { vueVimeoPlayer },
  data() {
    return {
      player: null,
      discardDialog: {
        open: false,
        allowLeave: false,
        to: null,
      },
      imageDialog: {
        open: false,
        image: null,
        video: false,
      },
      currentImage: 0,
    };
  },
  async mounted() {
    try {
      const { host, protocol } = window.location;

      this.player = await Player.connect(this.$route.params.accessToken, {
        playerWasmAssetsPath: `${protocol}//${host}/player`,
      });

      this.$refs.playerWrapper.appendChild(this.player.videoElement);

      this.player.on("stateChanged", (state) => {
        if (state === "ready") {
          this.player.play();
        }

        if (state == "ended") {
          if (this.discardDialog.allowLeave) return;

          this.discard();
        }
      });
    } catch (e) {
      console.log(e);

      this.discard();
    }
  },
  methods: {
    discard() {
      this.discardDialog = {
        ...this.discardDialog,
        open: false,
        allowLeave: true,
      };

      if (this.discardDialog.to) {
        this.$router.push({ path: this.discardDialog.to });
      } else {
        this.$router.go(-1);
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    //DISCARD DIALOG LOGIC
    if (!this.discardDialog.allowLeave) {
      this.discardDialog = {
        ...this.discardDialog,
        open: true,
        to: to?.path,
      };

      return next(false);
    }

    try {
      this.player?.disconnect();
    } catch (e) {
      console.log(e);
    }

    next();

    location.reload();
  },
  watch: {
    "imageDialog.open"(newValue) {
      if (!newValue) {
        this.imageDialog = {
          open: false,
          image: null,
          video: null,
        };

        this.currentImage = 0;
      }
    },
  },
};
</script>

<style lang="scss" >
.player-wrapper {
  width: 1280px;
  height: 720px;

  video {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.img {
  width: 1200px;
  object-fit: contain;
}
</style>